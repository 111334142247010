import Vue from 'vue';
import Vuelidate from 'vuelidate'

window.Vue = Vue;
Vue.use(Vuelidate)

if (process.env.NODE_ENV === "development") {
    Vue.config.devtools = true;
} else {
    Vue.config.devtools = false;
}

import "./js/global-app";