import { widgetInitialisation } from "./functions/widget-init";

const widgetMappings = {};

widgetMappings["request-quote"] = async () => {
    try {
        return await import(/* webpackPrefetch: false, webpackChunkName: "request-quote" */ "./services/request-quote")
    } catch (error) {
        console.error("Error loading request-quote:", error);
        throw error;
    }
};

window.GlobalApp = {
    initializationHandlers: {},
    triggerQueue: {},

    registerInitializationHandler: function (key, callback) {
        this.initializationHandlers[key] = callback;
    },

    triggerInitialiation: function (key) {
        this.triggerQueue[key] = true;
    }
};

widgetInitialisation(initGlobalApp);

function initGlobalApp() {
    window.GlobalApp = new Vue({
        data: {
            initializationHooks: [],
            initializationHandlers: window.GlobalApp.initializationHandlers,
            triggerQueue: window.GlobalApp.triggerQueue,
        },

        methods: {
            getInitializationReadyHook: function () {
                var resolveCallback = null;
                var newInitializationHook = new Promise(function (resolve, reject) {
                    resolveCallback = resolve;
                });
                this.initializationHooks.push(newInitializationHook);

                return resolveCallback;
            },
            getAllAppsAreInitialized: function () {
                return Promise.all(this.initializationHooks);
            },
            registerInitializationHandler: function (key, callback) {
                this.initializationHandlers[key] = callback;
            },
            triggerInitialiation: function (key) {
                const that = this;
                if (widgetMappings.hasOwnProperty(key)) {
                    widgetMappings[key]()
                        .then(function () {
                            that.triggerInitializationHandler(key);
                        });
                } else {
                    console.log("No component for the '" + key + "' key was found;");

                    this.triggerInitializationHandler(key);
                }
            },
            triggerInitializationHandler(key) {
                if (this.initializationHandlers.hasOwnProperty(key)) {
                    this.initializationHandlers[key]();
                } else {
                    console.log("No handler for the '" + key + "' key was found;");
                }
            },
            flushInitializationTriggers: function () {
                for (var key in this.triggerQueue) {
                    if (this.triggerQueue.hasOwnProperty(key))
                        this.triggerInitialiation(key);
                }

                this.triggerQueue = {};
            },
        },
    });

    window.GlobalApp.flushInitializationTriggers();
}